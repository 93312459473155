<template>
  <v-container>
    <v-subheader class="mb-3">
      <v-btn
        icon
        small
        to="/"
      >
        <v-icon small>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <strong class="ml-2">{{ $t('post_tg_img') }}</strong>
    </v-subheader>
    <div class="text-center">
      <v-card
        color="box_bg"
      >
        <v-card-text
          v-if="todaycount<2 && countdownhour<1 && attime"
          class="text-center py-3"
        >
          <div
            v-if="f1!=null"
            class="nav_bg pa-3 rounded mx-auto"
          >
            <div class="mb-2">
              <v-btn
                depressed
                fab
                x-small
                dark
                color="red accent-2"
                @click="delimg"
              >
                <v-icon color="red1">
                  mdi-delete-forever
                </v-icon>
              </v-btn>
            </div>
              
            <img
              v-if="f1==null"
              width="100"
              src="../assets/img/pic/postimg-01.jpg"
            >
            <img
              v-if="f1!=null"
              width="100"
              :src="f1"
            >
          </div>
            
          <div
            v-if="f1==null"
            class="d-flex justify-center"
          >
            <v-avatar
              color="nav_bg"
              size="56"
            >
              <v-file-input
                ref="ff1"
                v-model="file1"
                hide-input
                accept="image/png, image/jpeg, image/jpg"
                prepend-icon="mdi-camera-plus"
                class="pa-0 ma-0 ml-2"
                style="flex: none!important;"
                @change="onSelectFile()"
              />
            </v-avatar>
          </div>
        </v-card-text>
        <v-card-text
          v-if="todaycount<2 && attime && countdownhour>0"
          class="text-center py-5 primary--text"
        >
          <v-chip>
            <v-icon
              left
            >
              mdi-clock-time-eight-outline
            </v-icon>
            {{ daojishi }}
          </v-chip>
          <div class="mt-3">
            {{ $t('xctjsj2') }}
          </div>
        </v-card-text>
        <v-card-text
          v-if="todaycount>1 && attime && countdownhour<1"
          class="text-center py-5 primary--text"
        >
          <v-icon
            x-large
            color="primary"
            class="mb-3"
          >
            mdi-clock-time-eight-outline
          </v-icon>
          <div>{{ $t('ytzntj2c2') }}</div>
        </v-card-text>
        <v-card-text
          v-if="!attime"
          class="text-center py-5 primary--text"
        >
          <v-icon
            x-large
            color="primary"
            class="mb-3"
          >
            mdi-clock-time-eight-outline
          </v-icon>
          <div>{{ $t('xzwfsy') }}</div>
        </v-card-text>
        <v-card-actions v-if="attime">
          <v-btn
            depressed
            block
            color="primary"
            class="text-center"
            :loading="loading"
            :disabled="todaycount>1 || countdownhour>0"
            @dblclick="dblclick"
            @click="savetgshare"
          >
            {{ $t('shangchuan') }}
          </v-btn>
        </v-card-actions>

        <v-divider class="opacity-3 my-2" />
        <v-card-title class="py-3 text-body-1">
          {{ $t('guize') }}
        </v-card-title>

        <v-card-text>
          1.{{ $t('post_tg_rule1') }}
          <div class="text-center my-2">
            <v-btn
              outlined
              dark
              rounded
              color="#25d366"
              :href="whatsappurl"
            >
              <v-icon left>
                mdi-whatsapp
              </v-icon>
              {{ $t('post_tg') }}
            </v-btn>
          </div>
          2.{{ $t('post_tg_rule2') }}
          <ul>
            <li>{{ $t('post_tg_rule2a') }}</li>
            <li>{{ $t('post_tg_rule2b') }}</li>
            <li>{{ $t('post_tg_rule2c') }}</li>
          </ul>

          <v-subheader class="px-0 subtitle-2">
            {{ $t('jlck') }}
          </v-subheader>
          <v-carousel
            cycle
            height="400"
          >
            <v-carousel-item
              v-for="p in 3"
              :key="'i'+p"
              :src="require('@/assets/img/pic/postimg-0'+p+'.jpg')"
            />
          </v-carousel>
        </v-card-text>

        <v-divider class="opacity-3 my-2" />
        <v-card-title
          v-if="list.length>0"
          class="py-3 text-body-1"
        >
          {{ $t('lsjl') }}
        </v-card-title>

        <v-list
          color="box_bg"
          class="text-caption pt-0"
        >
          <div
            v-for="(item, index) in list"
            :key="'h'+index"
          >
            <v-divider class="opacity-3" />
            <v-list-item>
              <v-list-item-action>
                {{ item.created }}
              </v-list-item-action>
              <v-list-item-content>
                <div>
                  <img
                    width="50"
                    class="mx-1"
                    :src="item.imgs"
                  >
                </div>
              </v-list-item-content>
              <v-list-item-action
                v-if="item.status==0"
                class="text-right"
              >
                {{ $t('daishenhe') }}
              </v-list-item-action>
              <v-list-item-action
                v-if="item.status==1"
                class="text-right"
              >
                <div>
                  <div class="orange--text font-weight-medium">
                    + R$ {{ item.money }}
                  </div>
                  <span class="green--text">{{ $t('yifafang') }}</span>
                </div>
              </v-list-item-action>
              <v-list-item-action
                v-if="item.status==-1"
                class="text-right"
              >
                <div class="text-center">
                  <div class="red--text">
                    {{ $t('weitongguo') }}
                  </div>
                </div>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
      </v-card>
    </div>
    <template v-if="showdialog">
      <Login
        v-model="loginreg"
        @closeloginreg="closeloginreg"
        @showforget="showforget"
      />
    </template>
    <template v-if="forget">
      <Forget
        @closeforget="closeforget"
      />
    </template>
  </v-container>
</template>

<script>
import Login from '@/components/Login.vue'
import Forget from '@/components/Forget.vue'

export default {
  components: {
    Login,
    Forget
  },
  data: () => ({
    list: [],
    allowupload: false,
    showdialog: false,
    loginreg: false,
    forget: false,
    file1: null,
    f1: null,
    loading: false,
    attime: true,
    todaycount: 2,
    countdownhour: 0,
    hourtimer: null,
    daojishi: '',
    sms_domain: '',
    whatsappurl: '',
  }),
  computed: {

  },
  watch: {

  },
  created() {
    this.$server.conf().then((response) => {
      this.sms_domain = response.data.sms_domain

      this.whatsappurl = 'whatsapp://send?text=🎁Plataforma dando 12,77 se cadastrar no CPF 🎁Bora aproveitar que tá pagando muito 💸 '+this.sms_domain+' 💗Meusss ganhos de agora nem acredito 🥺🙏🏼agora R$ 12.5 de bônus posta no Facebook💸 corra vamos testar👉 '+this.sms_domain+' 🤝 Amigos, por favor me respondam se você está interessado ou não.'
      if( (/iphone|ipod|ipad/.test( window.navigator.userAgent.toLowerCase() )) && (/safari/.test( window.navigator.userAgent.toLowerCase() ))==false ){
        this.whatsappurl = "pgb://"+encodeURIComponent(this.whatsappurl)
      }
    })
  },
  mounted() {
    this.tgsharerecords()
  },
  beforeDestroy() {

  },
  methods: {
    tgsharerecords() {
      if(!this.getStorage('member_id')){
        return false
      }
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.tgsharerecords(paramObj).then((response) => {
        if(response.code==200){
          this.list = response.data.list
          this.todaycount = response.data.todaycount
          this.countdownhour = response.data.countdownhour
          this.attime = response.data.attime
          this.hourcountdown()
        }
      })
    },
    hourcountdown() {
      this.hourtimer = setInterval(() => {
        if (this.countdownhour > 0) {
          this.countdownhour--
          let hours = Math.floor(this.countdownhour / 3600)
          let minutes = Math.floor(this.countdownhour / 60) % 60
          let seconds = this.countdownhour % 60
          this.daojishi = hours+':'+minutes+':'+seconds
        }else{
          this.daojishi = ''
          clearInterval(this.hourtimer)
          this.hourtimer = null
        }
      }, 1000)
    },
    showloginreg(tab) {
      this.loginreg = tab
      this.showdialog = true
    },
    closeloginreg() {
      this.loginreg = false
      this.showdialog = false
    },
    showforget() {
      this.forget = true
    },
    closeforget() {
      this.forget = false
    },
    dblclick() {
      console.log('dblclick')
    },
    delimg() {
      this.file1 = null
      this.f1 = null
    },
    onSelectFile() {
      if(!this.getStorage('member_id')){
        this.showloginreg('login')
        return false
      }
      if(this.file1.size>20000000){
        this.file1 = null
        this.$snackbar.error('O tamanho da imagem deve ser inferior a 20 MB')
        return false
      }
      let paramObj = {
        member_id: this.getStorage('member_id'),
        file: this.file1
      }
      this.$server.uploadtgshare(paramObj).then((response) => {
        if(response.code==200){
          this.f1 = response.data.url
        }else{
          this.file1 = null
          this.f1 = null
          this.$snackbar.error(response.msg)
        }
      })
    },
    savetgshare() {
      this.loading = true
      if(this.f1==null){
        this.$snackbar.error(this.$t('zs1zt'))
        this.loading = false
        return false
      }
      let paramObj = {
        member_id: this.getStorage('member_id'),
        imgurl: this.f1,
      }
      this.$server.savetgshare(paramObj).then((response) => {
        if(response.code==200){
          this.file1 = null
          this.f1 = null
          this.tgsharerecords()
        }else{
          this.$snackbar.error(response.msg)
        }
        this.loading = false
      })
    },
  }

}
</script>